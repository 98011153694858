// Get all table elements on the page
var tables = document.getElementsByTagName('table');

// Iterate through each table
for (var i = 0; i < tables.length; i++) {
  var table = tables[i];
  var tbody = table.getElementsByTagName('tbody')[0];

  // Check if the table body is empty
  if (!tbody || tbody.rows.length === 0) {
    // Remove the table
    table.parentNode.removeChild(table);

    // Create a new paragraph element
    var paragraph = document.createElement('p');

    // Set the text content of the paragraph
    paragraph.textContent = 'No new entries';

    // Add the 'p-4' class to the paragraph
    paragraph.classList.add('p-4');

    // Find the table-responsive element
    var tableResponsive = document.querySelector('.table-responsive');

    // Append the paragraph to the table-responsive element
    if (tableResponsive) {
      tableResponsive.appendChild(paragraph);
    }
  }
}
